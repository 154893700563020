import React, { useState } from 'react';
import Layout from '../components/Layout';
import { Section } from 'src/components/Section';
import { Card, Tags, HeroCard } from 'src/components/Blog';
import { graphql } from 'gatsby';
import { TopbarStaticSpace } from 'src/components/Topbar';
import sizes from 'src/style/sizes';
import { mediaQueries } from 'src/style/breakpoints';

const TagsList = [
  'Tous',
  'Entrepreneuriat',
  'Tech',
  'Apprendre à coder',
  'Tutoriel',
  'Témoignage',
  'Reconversion professionnelle',
];

const BlogIndexTemplate = ({
  location,
  data: {
    allMarkdownRemark: { edges },
  },
  data,
}) => {
  const [tagSelected, setSelected] = useState(TagsList[0]);

  const blogPostHasDate = edges.filter((edge) => !!edge.node.frontmatter.date);

  const blogPostIsNotCovered = edges
    .filter(
      (edge) =>
        blogPostHasDate &&
        edge.node.fields.slug !== data.site.siteMetadata.coverArticle &&
        edge.node.frontmatter.published === true
    )
    .map((edge) => <Card key={edge.node.id} post={edge.node} />);

  const filteredPostsWithTag =
    tagSelected === TagsList[0]
      ? blogPostIsNotCovered
      : blogPostIsNotCovered.filter(
          (edge) => edge.props.post.frontmatter.tags.indexOf(tagSelected) !== -1
        );

  const highlightedPost = edges.find(
    (edge) => edge.node.fields.slug === data.site.siteMetadata.coverArticle
  );

  const handleHighlightedPost = <HeroCard post={highlightedPost.node} />;

  const handleOnClick = (item) => {
    setSelected(item);
  };

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName="Blog"
      pageType="page"
      metaTitle="Blog - Formation Développeur Web et Mobile | Le Reacteur"
      metaDescription="Le Blog du Reacteur afin de retrouver toutes les ressources utiles pour vous aider à devenir Développeur Web et Mobile."
    >
      <TopbarStaticSpace />
      <Section
        theme="primary"
        css={{
          [mediaQueries.phoneOnly]: {
            height: sizes.purpleBgHeightMobile,
            paddingTop: 0,
          },
          [mediaQueries.tabletPortraitUp]: {
            height: sizes.purpleBgHeightTablet,
            paddingTop: 0,
          },
          [mediaQueries.tabletLandscapeUp]: {
            paddingTop: 40,
            height: sizes.purpleBgHeightDesktop,
          },
        }}
      />

      <Section theme="secondary">
        <div
          css={{
            position: 'relative',
            marginTop: -380,
            [mediaQueries.phoneOnly]: {
              marginTop: -190,
            },
            [mediaQueries.tabletLandscapeUp]: {
              marginTop: sizes.marginTopContainer,
            },
          }}
        >
          <div>{handleHighlightedPost} </div>
          <Tags TagsItems={TagsList} handleOnClick={handleOnClick} tagSelected={tagSelected} />

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              flexWrap: 'wrap',
              [mediaQueries.phoneOnly]: {
                marginTop: 30,
              },
            }}
          >
            {filteredPostsWithTag.map((edge) => (
              <Card key={edge.props.post.id} post={edge.props.post} />
            ))}
          </div>
          {/*     <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 180,
              marginBottom: 60,
            }}
          >
            <SectionSubtitle>Suivez-nous sur Instagram @lereacteur.io</SectionSubtitle>
          </div> */}
        </div>
      </Section>
      {/*  <InstagramFeed /> */}
    </Layout>
  );
};

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        coverArticle
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            title
            author
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 970)
              }
            }
            tags
            preview
            published
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
